import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientRoutineResistanceExerciseSetRequestModel } from './routine-resistance-exercise-set.request-model';
import { ClientRoutineResistanceExerciseSetResponseModel } from './routine-resistance-exercise-set.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientRoutineResistanceExerciseSetService
{
	private http = inject(HttpClient);


	public get(routineResistanceExerciseSetId: string): Observable<ClientRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine-resistance-exercise-set/${ routineResistanceExerciseSetId }`;

		return this.http.get<ClientRoutineResistanceExerciseSetResponseModel>(url);
	}

	public getByRoutineResistanceExerciseId(routineResistanceExerciseId: string): Observable<ClientRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/training/routine-resistance-exercise-sets/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<ClientRoutineResistanceExerciseSetResponseModel[]>(url);
	}

	public create(request: ClientRoutineResistanceExerciseSetRequestModel) : Observable<ClientRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine-resistance-exercise-set`;

		return this.http.post<ClientRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public update(request: ClientRoutineResistanceExerciseSetRequestModel) : Observable<ClientRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine-resistance-exercise-set/${ request.routineResistanceExerciseSetId }`;

		return this.http.put<ClientRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public updateRange(request: ClientRoutineResistanceExerciseSetRequestModel[]) : Observable<ClientRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/training/routine-resistance-exercise-sets`

		return this.http.put<ClientRoutineResistanceExerciseSetResponseModel[]>(url, request);
	}
}
