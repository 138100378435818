import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientRoutineCreateFromTemplateRequestModel } from './routine.create-from-template-request-model';
import { ClientRoutineCreateRequestModel } from './routine.create-request-model';
import { ClientRoutineResponseModel } from './routine.response-model';
import { ClientRoutineUpdateRequestModel } from './routine.update-request-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientRoutineService
{
	private http = inject(HttpClient);


	public get(routineId: string) : Observable<ClientRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine/${ routineId }`;

		return this.http.get<ClientRoutineResponseModel>(url);
	}

	public getAll() : Observable<ClientRoutineResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/training/routines`;

		return this.http.get<ClientRoutineResponseModel[]>(url);
	}

	public getByClientId(clientId : string) : Observable<ClientRoutineResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/training/routines/client/${ clientId }`;

		return this.http.get<ClientRoutineResponseModel[]>(url);
	}

	public create(request: ClientRoutineCreateRequestModel) : Observable<ClientRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine`;

		return this.http.post<ClientRoutineResponseModel>(url, request);
	}
	
	public createFromTemplate(request: ClientRoutineCreateFromTemplateRequestModel) : Observable<ClientRoutineResponseModel>
	{
		const url = `${ environment.baseUrl   }/client/training/routine/template`;

		return this.http.post<ClientRoutineResponseModel>(url, request);
	}

	public update(request: ClientRoutineUpdateRequestModel) : Observable<ClientRoutineResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/training/routine/${ request.routineId }`;

		return this.http.put<ClientRoutineResponseModel>(url, request);
	}

	public updateRange(request: ClientRoutineUpdateRequestModel[]) : Observable<ClientRoutineResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/training/routines`;

		return this.http.put<ClientRoutineResponseModel[]>(url, request);
	}
}
